import * as React from "react"
import type { HeadFC } from "gatsby"
import Layout from "../components/layout"
import '../styles/global.css'

const pageStyles = {
    color: "#232129",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
  }

const IndexPage = () => {
    return (
        <main className="bg-white" style={pageStyles}>
          <Layout>
                <div className="max-w-sm w-full lg:max-w-full lg:flex flex justify-center border-rose-600">
                  <div className="flex flex-col md:flex-row md:max-w-xl rounded-lg bg-white shadow-lg ">
                    <div className="border-r border-b border-l bg-slate-100 border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-600 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                      <div className="mb-8">
                        <img className=" w-full h-96 md:h-auto object-cover md:w-50 rounded-lg " src="/images/a4.jpeg" alt="" />
                          <div className="p-6 flex flex-col justify-start">
                            <h5 className="text-gray-900 text-4xl font-medium mb-2">Karen</h5>
                          <div className="w-full h-6 bg-gray-200 mb-4 rounded-full dark:bg-gray-700">
                            <div className="h-6 bg-yellow-500 rounded-full dark:bg-blue-500" style={{width:"58%"}}></div>
                          </div>
                        <p className="uppercase font-bold text-xl">Hola, yo soy Karen</p>
                        <p className="uppercase font-bold">Tengo 8 años</p>
                        <p className="uppercase font-bold">Vivo en playa del Carmen</p>
                        <p></p>
                        <p className="text-gray-500 text-sm">
                          Like any other six-year-old, Ganod loves to
                          play with toys and watch cartoons. Ganod came to 
                          Children's Place when he was just 11 months old, after 
                          he lost his parents. He currently attends Kindergarten.
                          Ganod's speech is delayed for his age, which impacts his progress in school. However, he does extremely well socially, and makes friends wherever he goes.
                      </p>
                      <div className="flex w-52 mt-5">
                        <span className="inline-flex items-center px-3 text-sm text-white font-bold bg-gray-200 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                          $
                        </span>
                        <input className="shadow appearance-none border  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="" type="text" placeholder="$50"></input>
                        <label className="text-black-300 text-sm font-bold  p-2">
                          Mensual
                        </label>
                        </div>
                          <div className="flex justify-center pt-8 pb-2">
                        <button  type="submit" className="shadow bg-blue-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-12 rounded">
                          <a href="#">Donar</a>
                        </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     </Layout>
   </main>
    )
    }

export default IndexPage

export const Head: HeadFC = () => <title>El Amor Te Elige A Ti</title>;
